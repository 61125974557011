body, p, blockquote, a {
    .font-sizeMD();
   font-weight: @fontWeightNormal; 
}
h1, h2, h3, h4, h5, h6 {
   font-weight: @fontWeightBold; 
}
h1 {
    .font-sizeXXL();
}
h2 {
    .font-sizeXL();
}
h3 {
    .font-sizeLG();
}
h4 {
    .font-sizeMD();
}
h5 {
    .font-sizeSM();
}
h6 {
    .font-sizeXS();
}
b, strong {
    font-weight: @fontWeightBold;
}
a {
    color: @colorPrimary;
    text-decoration: none;
    &:hover, 
    &:focus {
        color: @colorPrimary;
    }
}

main.main {
    >header.main-header {
        >section.header-section {
            >.section-header {
			    height: 55px;
			    background-color: #3d3d3d;
            	.widget-container {
				    min-height: 55px;
				    position: relative;
				    align-items: stretch;
				    p.widget-text {
				    	color: #fff;
				    	font-size: 0.9rem;
				    	line-height: 14px;
				    	margin: 14px 0;
				    }
				    ul {
					    width: max-content;
					    padding: 0;
					    margin: 0 0 0 auto;
				    	>li {
				    		float: right;
						    list-style: none;
						    padding: 0;
						    margin: 0;
						    text-align: center;
				    		>a {
							    display: block;
							    width: 35px;
							    height: 35px;
							    padding: 5px;
							    margin: 10px;
							    background-color: #fff;
							    border-radius: 100%;
				    			>i {
				    				color: #000;
				    			}
				    		}
				    	}
				    }
					&:before {
					    content: '';
					    position: absolute;
					    right: 100%;
					    width: 2000px;
					    bottom: 0;
					    top: 0;
					    background-color: #FFF;
					}
            		&:after {
						content: '';
						position: absolute;
						right: 100%;
						border-style: solid;
						border-width: 28px;
						bottom: 0;
						top: -1px;
						background-color: #FFF;
						transition: all .3s;
            			border-color: #3d3d3d #3d3d3d transparent transparent;	
            		}
            	}
            }
            >.section-middle {
            	a.widget-image {
				    position: relative;
				    display: block;
				    >img {
				    	max-height: 70px;
				    }
            	}
            	nav.navbar {
            		padding: 0;
            		ul.navbar-nav {
            			>li.nav-item {
            				margin: 20px 0;
            				>a.nav-link {
            					color: #000;
							    font-weight: 500;
							    text-align: center;
							    font-size: .9rem;
							    height: 40px;
							    padding: 0 1rem;
							    line-height: 40px;
							    text-transform: uppercase;
            				}
            			}
            		}
            		.widget-call {
            			>span {
						    font-size: 1.4rem;
						    float: left;
						    margin-right: .5rem;
            			}
            			p {
						    margin: 0;
						    text-align: left;
						    font-size: .9rem;
						    text-transform: uppercase;
						    font-weight: 500;
						    line-height: 16px;
						    >b {
							    min-width: 150px;
							    display: block;
						    }
            			}
            		}
            	}
            }
            >.section-footer {
            	
            }
        }
    }
    >section.main-section {
        >.section-center {
        	>nav.block-breadcrumb {
        		background-color: #000;
        		border-radius: 0 0 1rem 1rem;
        		ol.breadcrumb {
				    background-color: transparent;
				    border-radius: unset;
				    margin: 0;
        			>li.breadcrumb-item {
        				color:#fff;
        				>a {
        					color:#fff;
        				}
        			}
        		}
        	}
            .block-page {
			    padding: 2rem 0;
			    background-color: rgba(255, 255, 255, 75%);
            	>.page-header {
					margin: 0 auto 1rem auto;
				    width: max-content;
				    max-width: 80%;
					box-shadow: 0px 10px 5px -10px @colorPrimary;
            		>h1 {
						text-align: center;
						padding: 0 2rem .5rem 2rem;
            		}
            	}
            }
        }
    }
    >footer.main-footer {
    	background-color: @colorSecondary;
        >section.footer-section {
        	padding: 4rem 0 0 0;
        	>.section-middle {
        		padding-bottom: 4rem;
        		.menu-title {
        			>h4 {
        				color: #fff;	
        				margin: 0;
        			}
        			>p {
	        			color: #fff;
						opacity: .7;	
        			}
        		}
        		.widget-image {
				    width: 100%;
				    display: block;	
				    >img {
				    	width: 100%;	
				    }
        		}
        		ul.list-group {
    				list-style: none;
				    padding: 0;
				    margin: 0;
        			>li.list-group-item {
					    list-style: none;
					    padding: 0;
					    margin: .25rem 0;
					    background-color: transparent;
					    border: none;
					    border-radius: 0;
					    color: #fff;
					    font-size: .9rem;
					    >span.icon {
					    	
					    }
					    >p.text {
					    	margin: 0;
					    	font-size: .9rem;
					    }
					    .item-link {
						    color: #fff;
						    opacity: .7;
						    padding: .5rem 0 .25rem 0;
						    border-bottom: 1px solid #ccc;
						    font-size: .9rem;
					    }
        			}
        		}
        		.carousel {
        			>.carousel-inner >.carousel-item  {
        				>img {
        					border-radius: 1rem;
        				}
        				>.carousel-caption {
        					bottom: 0px;
        					>h5 {
        					    margin: 0;
							    background-color: #000;
							    padding: .5rem 1rem;
							    color: #fff;
							    border-radius: .5rem;
							    opacity: .7;
        					}
        				}
        			}
        			.carousel-control-prev,
        			.carousel-control-next {
        				>.carousel-control-prev-icon,
        				>.carousel-control-next-icon {
						    background-color: @colorPrimary;
						    color: #fff;
						    box-shadow: 0px 0px 0px 5px red;
						    border-radius: 1rem;
        				}
        			}
        		}
        	}
        	>.section-footer {
        		background-color: @colorPrimary;
        		.block-copyright,
        		.block-legal-obligations {
        			color: #fff;
        			>a {
        				color: #fff;
        			}
        		}
        	}
        }
    }
}

.modal {
	.modal-content {
		background-color: #ffffff;
		.modal-header {
			background-color: #f0f0f0;
			>h5.modal-title {
				.font-sizeLG();
			    color: @colorPrimary;	
			}
		}
		.modal-body {
			&.block-user-cookie-control-permission {
				background-color: #ffffff;
			}
		}
		.modal-footer {
			background-color: #f0f0f0;
		}
	}
}
.display-only-screen {
	display: block;	
}
.display-only-mobile {
	display: none;	
}

@media screen and (max-width: 991px) {

}